import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(VRow, [
            _c(
              "p",
              {
                staticClass: "mx-auto",
                staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
              },
              [_c("strong", [_vm._v(_vm._s(_vm.$t("main_email")))])]
            ),
          ]),
          _c(
            VRow,
            { staticClass: "my-4" },
            [
              _c(VTextField, {
                attrs: {
                  color: "primary",
                  outlined: "",
                  dense: "",
                  rules: [_vm.required, _vm.accent_valid, _vm.email_valid],
                  placeholder: "email@email.com.br",
                  "data-test": "NewUser:RegisterEmail:InputEmail",
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                  },
                },
                model: {
                  value: _vm.User.Email,
                  callback: function ($$v) {
                    _vm.$set(_vm.User, "Email", $$v)
                  },
                  expression: "User.Email",
                },
              }),
            ],
            1
          ),
          _c(
            VBtn,
            {
              staticStyle: { width: "150px" },
              attrs: {
                "data-test": "NewUser:RegisterEmail:NextStep",
                type: "submit",
                color: "primary",
                loading: _vm.loading,
              },
            },
            [
              _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                _vm._v(_vm._s(_vm.$t("next"))),
              ]),
            ]
          ),
          _c("br"),
          _c(
            "label",
            {
              staticStyle: {
                "margin-top": "30px",
                display: "block",
                color: "var(--primary) !important",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/auth")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("login_button")))]
          ),
        ],
        1
      ),
      _vm.alert
        ? _c(VAlert, { staticClass: "ma-2", attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.alert) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }