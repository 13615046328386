import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.Step == 3
    ? _c("div", [
        _vm.showThesis
          ? _c(
              "div",
              [
                _c(VRow, { staticClass: "mb-4" }, [
                  _c(
                    "p",
                    {
                      staticClass: "mx-auto",
                      staticStyle: {
                        "font-size": "1.5rem",
                        color: "var(--dark)",
                      },
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("investment_thesis"))),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "text-left",
                    staticStyle: { color: "var(--dark)" },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("investment_thesis_1")) + " "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("investment_thesis_2")) + " "),
                  ]
                ),
                _c(
                  VRow,
                  {
                    staticStyle: {
                      "max-width": "275px",
                      margin: "0 auto",
                      display: "flex",
                    },
                    attrs: { justify: "center" },
                  },
                  [
                    _c(
                      VCol,
                      { staticClass: "col_btn_back px-1 py-1" },
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "btn_back",
                            attrs: { color: "primary", outlined: "" },
                            on: { click: _vm.go_back },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "1.3rem" } },
                              [_vm._v(_vm._s(_vm.$t("go_back")))]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      VCol,
                      { staticClass: "col_btn_next px-1 py-1" },
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "btn_next",
                            attrs: {
                              color: "primary",
                              "data-test": "NewUser:RegisterCarteira:NextStep",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showThesis = false
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "1.3rem" } },
                              [_vm._v(_vm._s(_vm.$t("next")))]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(VRow, { staticClass: "mb-4" }, [
                  _c(
                    "p",
                    {
                      staticClass: "mx-auto",
                      staticStyle: {
                        "font-size": "1.5rem",
                        color: "var(--primary) !important",
                      },
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("administered_title"))),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "text-left",
                    staticStyle: { color: "var(--dark)" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("administered_1")) + " ")]
                ),
                _c(
                  "ul",
                  {
                    staticClass: "bulletPoints",
                    staticStyle: {
                      "text-align": "left",
                      color: "var(--dark)",
                      "font-weight": "bold",
                    },
                  },
                  [
                    _c("li", [
                      _vm._v(" " + _vm._s(_vm.$t("wallet_company_size")) + " "),
                    ]),
                    _c("li", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("wallet_company_industry")) + " "
                      ),
                    ]),
                    _c("li", [
                      _vm._v(" " + _vm._s(_vm.$t("wallet_total_size")) + " "),
                    ]),
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "text-left",
                    staticStyle: { color: "var(--dark)", "margin-top": "20px" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("administered_2")) + " ")]
                ),
                _c(
                  VRow,
                  { attrs: { justify: "center" } },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "carteiraBtn",
                        attrs: { color: "primary", outlined: "" },
                        on: {
                          click: function ($event) {
                            _vm.showThesis = true
                          },
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                          _vm._v(_vm._s(_vm.$t("go_back"))),
                        ]),
                      ]
                    ),
                    _c(
                      VBtn,
                      {
                        staticClass: "carteiraBtn",
                        attrs: {
                          color: "primary",
                          "data-test":
                            "NewUser:RegisterCarteira:CustomizeWallet",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("next")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "1.1rem",
                              "white-space": "pre-wrap",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("customize_wallet")))]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
      ])
    : _c(
        "div",
        { staticClass: "mx-5" },
        [
          _c(
            VForm,
            {
              ref: "form",
              attrs: { "lazy-validation": _vm.lazy },
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.nextBtn_click()
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(VRow, { staticClass: "mb-4" }, [
                _c(
                  "p",
                  {
                    staticClass: "mx-auto",
                    staticStyle: {
                      "font-size": "1.5rem",
                      color: "var(--primary) !important",
                    },
                  },
                  [_c("strong", [_vm._v(_vm._s(_vm.$t("customize_wallet")))])]
                ),
              ]),
              _c(VRow, { staticClass: "text-left" }, [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "1.3rem",
                      color: "var(--dark)",
                      margin: "5px 0px",
                    },
                  },
                  [
                    _vm.Step == 4
                      ? _c("strong", [
                          _vm._v(_vm._s(_vm.$t("company_revenue"))),
                        ])
                      : _vm.Step == 5
                      ? _c("strong", [
                          _vm._v(_vm._s(_vm.$t("industry_sector"))),
                        ])
                      : _vm.Step == 6
                      ? _c("strong", [
                          _vm._v(_vm._s(_vm.$t("invest_intended_question"))),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm.Step == 4
                  ? _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "1.1rem",
                          color: "var(--dark)",
                          margin: "5px 0px",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("company_revenue_description")) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                VRow,
                [
                  _vm.Step == 4
                    ? _c(
                        VRadioGroup,
                        {
                          staticStyle: { height: "100%" },
                          attrs: { center: "" },
                          model: {
                            value: _vm.User.CompanyRevenue,
                            callback: function ($$v) {
                              _vm.$set(_vm.User, "CompanyRevenue", $$v)
                            },
                            expression: "User.CompanyRevenue",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: { value: 0 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { color: "var(--dark)" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("0_revenue")))]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1208879375
                            ),
                          }),
                          _c(VRadio, {
                            staticClass: "mt-3",
                            attrs: { value: 1 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { color: "var(--dark)" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("1mil")))]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "var(--primary) !important",
                                            margin: "0px 5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "(" +
                                              _vm._s(_vm.$t("recomended")) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2400102597
                            ),
                          }),
                          _c(VRadio, {
                            staticClass: "mt-3",
                            attrs: { value: 2 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { color: "var(--dark)" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("5mil")))]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              4043937287
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm.Step == 5
                    ? _c(
                        VRow,
                        { staticClass: "mt-2", attrs: { align: "center" } },
                        [
                          _vm._l(_vm.sectorOptions, function (c, i) {
                            return _c(
                              VCol,
                              {
                                key: i,
                                staticClass: "my-0 py-0",
                                attrs: { cols: "12", md: "6" },
                              },
                              [
                                _c(VCheckbox, {
                                  staticClass: "my-0 py-0",
                                  attrs: { disabled: c.Disabled },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "var(--dark)",
                                                },
                                              },
                                              [_vm._v(_vm._s(c.Text))]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: c.Marked,
                                    callback: function ($$v) {
                                      _vm.$set(c, "Marked", $$v)
                                    },
                                    expression: "c.Marked",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          _c(
                            VCol,
                            {
                              staticClass: "my-0 py-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c(VCheckbox, {
                                staticClass: "my-0 py-0 mx-auto text-center",
                                on: {
                                  change:
                                    _vm.noPreferenceSectorsCheckbox_changed,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "var(--dark)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("no_preference"))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color:
                                                "var(--primary) !important",
                                              margin: "0px 5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "(" +
                                                _vm._s(_vm.$t("recomended")) +
                                                ")"
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.noPreferenceSector,
                                  callback: function ($$v) {
                                    _vm.noPreferenceSector = $$v
                                  },
                                  expression: "noPreferenceSector",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm.Step == 6
                    ? _c(
                        VRadioGroup,
                        {
                          attrs: { center: "" },
                          model: {
                            value: _vm.User.InvestIntended,
                            callback: function ($$v) {
                              _vm.$set(_vm.User, "InvestIntended", $$v)
                            },
                            expression: "User.InvestIntended",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: { value: 0 },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("up_to_50k")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c(VRadio, {
                            attrs: { value: 1 },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("up_to_100k")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c(VRadio, {
                            attrs: { value: 2 },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("up_to_500k")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c(VRadio, {
                            attrs: { value: 3 },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("up_to_1mil")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c(VRadio, {
                            attrs: { value: 4 },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("above_1mil")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticClass: "mt-1",
                  staticStyle: { "max-width": "275px", margin: "0 auto" },
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    VCol,
                    { staticClass: "col_btn_back px-1 py-1" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "btn_back",
                          attrs: { color: "primary", outlined: "" },
                          on: { click: _vm.go_back },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "font-size": "1.3rem" } },
                            [_vm._v(_vm._s(_vm.$t("go_back")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "col_btn_next px-1 py-1" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "btn_next",
                          attrs: {
                            "data-test":
                              "NewUser:RegisterCarteira:NextStepSizeCompany",
                            type: "submit",
                            color: "primary",
                            loading: _vm.Loading,
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "font-size": "1.3rem" } },
                            [_vm._v(_vm._s(_vm.$t("next")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }