import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(VRow, [
            _c(
              "p",
              {
                staticClass: "mx-auto",
                staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
              },
              [_c("strong", [_vm._v(_vm._s(_vm.$t("confirm_data")))])]
            ),
          ]),
          _c(
            "div",
            { staticStyle: { width: "200px", display: "inline-table" } },
            [
              _c(
                VRow,
                { staticClass: "my-4" },
                [
                  _c(
                    VTextField,
                    {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["##############"],
                          expression: "['##############']",
                        },
                      ],
                      attrs: {
                        color: "primary",
                        dense: "",
                        rules: [_vm.required],
                        disabled: _vm.disabled,
                        "hide-details": "",
                      },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ])
                          ) {
                            return null
                          }
                          $event.preventDefault()
                        },
                      },
                      model: {
                        value: _vm.User.CelPhone,
                        callback: function ($$v) {
                          _vm.$set(_vm.User, "CelPhone", $$v)
                        },
                        expression: "User.CelPhone",
                      },
                    },
                    [_vm._v(" " + _vm._s(this.User.CelPhone))]
                  ),
                ],
                1
              ),
              _c(
                VRow,
                { staticClass: "my-4" },
                [
                  _c(
                    VTextField,
                    {
                      attrs: {
                        color: "primary",
                        dense: "",
                        rules: [_vm.required],
                        disabled: _vm.disabled,
                      },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ])
                          ) {
                            return null
                          }
                          $event.preventDefault()
                        },
                      },
                      model: {
                        value: _vm.User.Email,
                        callback: function ($$v) {
                          _vm.$set(_vm.User, "Email", $$v)
                        },
                        expression: "User.Email",
                      },
                    },
                    [_vm._v(_vm._s(this.User.Email))]
                  ),
                ],
                1
              ),
              _vm.changeToSave == false
                ? _c("a", { on: { click: _vm.alterar } }, [
                    _vm._v(_vm._s(_vm.$t("change"))),
                  ])
                : _c("a", { on: { click: _vm.isEmailValid } }, [
                    _vm._v(_vm._s(this.$t("save"))),
                  ]),
            ],
            1
          ),
          _c(
            VRow,
            {
              staticClass: "mt-1",
              staticStyle: { "max-width": "275px", margin: "0 auto" },
              attrs: { justify: "center" },
            },
            [
              _c(
                VCol,
                { staticClass: "col_btn_back px-1 py-1" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "btn_back",
                      attrs: { color: "primary", outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("back")
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                        _vm._v(_vm._s(_vm.$t("go_back"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "col_btn_next px-1 py-1" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "btn_next",
                      attrs: {
                        "data-test": "NewUser:ConfirmData:NextStepConfimData",
                        disabled: _vm.disabled_next,
                        type: "submit",
                        color: "primary",
                        loading: _vm.Loading,
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                        _vm._v(_vm._s(_vm.$t("next"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.alert
        ? _c(VAlert, { staticClass: "ma-2", attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.alert) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }