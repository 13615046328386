import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(VRow, [
            _c(
              "p",
              {
                staticClass: "mx-auto",
                staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
              },
              [_c("strong", [_vm._v(_vm._s(_vm.$t("register")))])]
            ),
          ]),
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "my-0 py-0", attrs: { cols: "12" } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "mb-0 text-left",
                      staticStyle: {
                        "font-size": "1.1rem",
                        color: "var(--dark)",
                      },
                    },
                    [_c("strong", [_vm._v(_vm._s(_vm.$t("account_type")))])]
                  ),
                  _c(
                    VRadioGroup,
                    {
                      attrs: { row: "" },
                      on: { change: _vm.juridical_type_changed },
                      model: {
                        value: _vm.User.JuridicalType,
                        callback: function ($$v) {
                          _vm.$set(_vm.User, "JuridicalType", $$v)
                        },
                        expression: "User.JuridicalType",
                      },
                    },
                    [
                      _c(
                        VRow,
                        { staticClass: "mx-1", attrs: { justify: "start" } },
                        [
                          _c(VRadio, {
                            staticClass: "mr-8",
                            attrs: { value: 0 },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("fisical_person")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c(VRadio, {
                            attrs: { value: 1 },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("legal_person")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "my-0 py-0",
                  attrs: {
                    cols: "12",
                    md:
                      _vm.User.JuridicalType ==
                      _vm.JuridicalTypeEnum.NaturalPerson
                        ? 6
                        : 12,
                  },
                },
                [
                  _c(VTextField, {
                    staticClass: "mt-10",
                    staticStyle: { "margin-top": "4px !important" },
                    attrs: {
                      "data-test": "NewUser:RegisterInfo:FirstName",
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                      label: _vm.$t("name_register"),
                    },
                    model: {
                      value: _vm.User.Name,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "Name", $$v)
                      },
                      expression: "User.Name",
                    },
                  }),
                ],
                1
              ),
              _vm.User.JuridicalType == _vm.JuridicalTypeEnum.NaturalPerson
                ? _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      staticStyle: { "margin-top": "4px" },
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c(VTextField, {
                        attrs: {
                          "data-test": "NewUser:RegisterInfo:LastName",
                          color: "primary",
                          outlined: "",
                          dense: "",
                          label: _vm.$t("last_name"),
                        },
                        model: {
                          value: _vm.User.LastName,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "LastName", $$v)
                          },
                          expression: "User.LastName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "my-0 py-0", attrs: { cols: "12" } },
                [
                  _c(VCombobox, {
                    attrs: {
                      "data-test": "NewUser:RegisterInfo:Nationality",
                      color: "primary",
                      dense: "",
                      outlined: "",
                      disabled:
                        _vm.User.JuridicalType ==
                        _vm.JuridicalTypeEnum.LegalPerson,
                      items: _vm.countries,
                      "item-text": "name",
                      "return-object": "",
                      rules: [_vm.required],
                      label: _vm.$t("nationality"),
                    },
                    on: { change: _vm.nationality_code_changed },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function (data) {
                          return [
                            _c("img", {
                              staticStyle: { width: "20px", height: "auto" },
                              attrs: { src: data.item.flag, alt: "" },
                            }),
                            _c("span", { staticClass: "mx-2" }, [
                              _vm._v(_vm._s(data.item.name)),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _c("img", {
                              staticStyle: { width: "20px", height: "auto" },
                              attrs: { src: data.item.flag, alt: "" },
                            }),
                            _c("span", { staticClass: "mx-2" }, [
                              _vm._v(_vm._s(data.item.name)),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.nationalitySelected,
                      callback: function ($$v) {
                        _vm.nationalitySelected = $$v
                      },
                      expression: "nationalitySelected",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "my-0 py-0", attrs: { cols: "12", md: "6" } },
                [
                  _vm.User.Nationality == "Brazil" ||
                  _vm.User.Nationality == "Brasil"
                    ? _c(VTextField, {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value:
                              _vm.User.JuridicalType ==
                              _vm.JuridicalTypeEnum.NaturalPerson
                                ? "###.###.###-##"
                                : "##.###.###/####-##",
                            expression:
                              "\n            User.JuridicalType == JuridicalTypeEnum.NaturalPerson\n              ? '###.###.###-##'\n              : '##.###.###/####-##'\n          ",
                          },
                        ],
                        attrs: {
                          "data-test": "NewUser:RegisterInfo:Cpf",
                          color: "primary",
                          dense: "",
                          outlined: "",
                          type: "tel",
                          rules: [_vm.required, _vm.social_number_valid],
                          label:
                            _vm.User.JuridicalType ==
                            _vm.JuridicalTypeEnum.NaturalPerson
                              ? _vm.$t("cpf")
                              : _vm.$t("company_id"),
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.User.SocialNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "SocialNumber", $$v)
                          },
                          expression: "User.SocialNumber",
                        },
                      })
                    : _c(VTextField, {
                        attrs: {
                          color: "primary",
                          dense: "",
                          outlined: "",
                          type: "tel",
                          label:
                            _vm.User.JuridicalType ==
                            _vm.JuridicalTypeEnum.NaturalPerson
                              ? _vm.$t("passport")
                              : _vm.$t("company_id"),
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.User.SocialNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "SocialNumber", $$v)
                          },
                          expression: "User.SocialNumber",
                        },
                      }),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "my-0 py-0", attrs: { cols: "12", md: "6" } },
                [
                  _c(VTextField, {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: ["##/##/####"],
                        expression: "['##/##/####']",
                      },
                    ],
                    class: _vm.field,
                    attrs: {
                      "data-test": "NewUser:RegisterInfo:Birthday",
                      dense: "",
                      outlined: "",
                      rules: [
                        function (v) {
                          return (!!v && v.length == 10) || _vm.required()
                        },
                      ],
                      placeholder: "DD/MM/YYYY",
                      type: "tel",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.User.Birth = _vm.parseDate(_vm.date)
                        _vm.check_date()
                      },
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                  _vm.date == "" && _vm.fieldsRequired == true
                    ? _c(
                        "p",
                        {
                          staticClass: "mb-4 mt-n4 ml-2",
                          staticStyle: { color: "red", "font-size": "11px" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("required")) + " ")]
                      )
                    : _vm._e(),
                  !_vm.isDateValid
                    ? _c(
                        "p",
                        {
                          staticClass: "mb-4 mt-n3 ml-3",
                          staticStyle: { color: "red", "font-size": "11px" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("valid_date")) + " ")]
                      )
                    : !_vm.isDateAboveLegal
                    ? _c(
                        "p",
                        {
                          staticClass: "mb-4 mt-n4 ml-2",
                          staticStyle: { color: "red", "font-size": "11px" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("min_18")) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "my-0 py-0", attrs: { cols: "12", md: "4" } },
                [
                  _c(VSelect, {
                    attrs: {
                      color: "primary",
                      dense: "",
                      outlined: "",
                      items: _vm.countries,
                      "item-text": "name",
                      "return-object": "",
                      rules: [_vm.required],
                      label: _vm.$t("country_code"),
                    },
                    on: { change: _vm.country_code_changed },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function (data) {
                          return [
                            _c("span", { staticClass: "mx-2" }, [
                              _vm._v(_vm._s(data.item.callingCodes[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.User.CountryCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "CountryCode", $$v)
                      },
                      expression: "User.CountryCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "my-0 py-0", attrs: { cols: "12", md: "8" } },
                [
                  _c(VTextField, {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value:
                          _vm.User.CountryCode &&
                          _vm.User.CountryCode.callingCodes[0] == "55"
                            ? "(##) #####-####"
                            : "",
                        expression:
                          "\n            User.CountryCode && User.CountryCode.callingCodes[0] == '55'\n              ? '(##) #####-####'\n              : ''\n          ",
                      },
                    ],
                    attrs: {
                      "data-test": "NewUser:RegisterInfo:Cellphone",
                      color: "primary",
                      dense: "",
                      outlined: "",
                      autocomplete: "off",
                      type: "tel",
                      rules: [_vm.required, _vm.phone_valid],
                      label: _vm.$t("celphone"),
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "space", 32, $event.key, [
                            " ",
                            "Spacebar",
                          ])
                        ) {
                          return null
                        }
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.User.CelPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "CelPhone", $$v)
                      },
                      expression: "User.CelPhone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "my-0 py-0", attrs: { cols: "12", md: "6" } },
                [
                  _c(VTextField, {
                    ref: "user.UserPassword",
                    attrs: {
                      "data-test": "NewUser:RegisterInfo:Password",
                      color: "primary",
                      outlined: "",
                      dense: "",
                      label: _vm.$t("password"),
                      "append-icon": _vm.show1 ? "mdi-eye" : "mdi-eye-off",
                      rules: [
                        _vm.required,
                        _vm.min_valid,
                        _vm.uppercase_required,
                        _vm.lowercase_required,
                        _vm.number_required,
                        _vm.special_required,
                        _vm.accent_valid,
                      ],
                      type: _vm.show1 ? "text" : "password",
                      name: "password",
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "space", 32, $event.key, [
                            " ",
                            "Spacebar",
                          ])
                        ) {
                          return null
                        }
                        $event.preventDefault()
                      },
                      "click:append": function ($event) {
                        _vm.show1 = !_vm.show1
                      },
                    },
                    model: {
                      value: _vm.User.UserPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "UserPassword", $$v)
                      },
                      expression: "User.UserPassword",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "my-0 py-0", attrs: { cols: "12", md: "6" } },
                [
                  _c(VTextField, {
                    attrs: {
                      "data-test": "NewUser:RegisterInfo:ConfirmPassword",
                      color: "primary",
                      outlined: "",
                      dense: "",
                      label: _vm.$t("re_password"),
                      "append-icon": _vm.show2 ? "mdi-eye" : "mdi-eye-off",
                      rules: [_vm.required, _vm.passwordsMatch],
                      type: _vm.show2 ? "text" : "password",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.show2 = !_vm.show2
                      },
                    },
                    model: {
                      value: _vm.rePassword,
                      callback: function ($$v) {
                        _vm.rePassword = $$v
                      },
                      expression: "rePassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    VDialog,
                    {
                      attrs: { persistent: "" },
                      model: {
                        value: _vm.terms,
                        callback: function ($$v) {
                          _vm.terms = $$v
                        },
                        expression: "terms",
                      },
                    },
                    [
                      _c("TermosPDFModal", {
                        key: _vm.terms,
                        attrs: {
                          Doc: _vm.use_terms_text,
                          type: "terms",
                          Header: _vm.$t("terms_use"),
                        },
                        on: {
                          agree: _vm.agree_terms,
                          decline: _vm.decline_terms,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(VCheckbox, {
                    staticClass: "my-0 py-0",
                    attrs: {
                      "data-test": "NewUser:RegisterInfo:UseTerms",
                      rules: [_vm.required],
                      readonly: "",
                    },
                    on: { click: _vm.open_terms },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              { staticStyle: { color: "var(--dark)" } },
                              [
                                _vm._v(_vm._s(_vm.$t("read_agreed"))),
                                _c(
                                  "a",
                                  {
                                    staticClass: "ml-1",
                                    on: { click: _vm.open_terms },
                                  },
                                  [
                                    _c(
                                      "strong",
                                      {
                                        staticStyle: {
                                          color: "var(--dark)",
                                          "text-decoration": "underline",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("terms_use")))]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.User.Terms,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "Terms", $$v)
                      },
                      expression: "User.Terms",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    VDialog,
                    {
                      attrs: { persistent: "" },
                      model: {
                        value: _vm.carteira,
                        callback: function ($$v) {
                          _vm.carteira = $$v
                        },
                        expression: "carteira",
                      },
                    },
                    [
                      _c("CarteiraPDFModal", {
                        key: _vm.redraw,
                        staticStyle: { "overflow-y": "auto !important" },
                        attrs: {
                          Doc: _vm.signed_portfolio_text,
                          BtnPlaceholder: _vm.$t("signed_portfolio"),
                          Header: _vm.$t("signed_portfolio_extended"),
                          type: "walletSignedPortfolio",
                        },
                        on: {
                          agree: _vm.agree_carteira,
                          decline: _vm.decline_carteira,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(VCheckbox, {
                    staticClass: "my-0 py-0",
                    attrs: {
                      "data-test": "NewUser:RegisterInfo:WalletSignedPortfolio",
                      rules: [_vm.required],
                      readonly: "",
                    },
                    on: { click: _vm.open_carteira },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              { staticStyle: { color: "var(--dark)" } },
                              [
                                _vm._v(_vm._s(_vm.$t("read_agreed"))),
                                _c(
                                  "a",
                                  {
                                    staticClass: "ml-1",
                                    on: { click: _vm.open_carteira },
                                  },
                                  [
                                    _c(
                                      "strong",
                                      {
                                        staticStyle: {
                                          color: "var(--dark)",
                                          "text-decoration": "underline",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("signed_portfolio"))
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.User.SignedPortfolio,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "SignedPortfolio", $$v)
                      },
                      expression: "User.SignedPortfolio",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            {
              staticStyle: {
                "max-width": "275px",
                margin: "0 auto",
                display: "flex",
              },
              attrs: { justify: "center" },
            },
            [
              _c(
                VCol,
                { staticClass: "col_btn_next px-1 py-1" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "btn_next",
                      attrs: {
                        "data-test": "NewUser:RegisterInfo:NextStep",
                        type: "submit",
                        color: "primary",
                        loading: _vm.loading,
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                        _vm._v(_vm._s(_vm.$t("next"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.alert
        ? _c(VAlert, { staticClass: "ma-2", attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.$t(_vm.alert)) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }